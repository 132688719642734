import { Cta, Footer, Hero, ListSection, Navbar, Partners, UniqueSellingPoints } from '@pidk/frontpage'
import { TiltDirection } from '@pidk/frontpage/src/components/FramedImage/FramedImage'
import type { LinkButtonProps } from '@pidk/frontpage/src/components/LinkButton/LinkButton'
import { LinkButtonSizeVariants } from '@pidk/frontpage/src/components/LinkButton/LinkButton'
import { StyledTextVariant } from '@pidk/frontpage/src/components/StyledText/StyledText'
import { LinkedInLogoIcon } from '@radix-ui/react-icons'
import Head from 'next/head'

import { Routes } from '@/constants/Routes'

const EMAIL_ADDRESS = 'edustart@edg.nl'
const EMAIL_ADDRESS_CUSTOMER_SERVICE = 'klantenservice@onderwijsinformatie.nl'

const navbarItems: LinkButtonProps[] = [
  {
    label: 'Hulp nodig?',
    href: '#footer',
    transparent: true,
    size: LinkButtonSizeVariants.SMALL
  },
  {
    label: 'Neem contact op',
    href: '#call-to-action',
    isCta: true,
    size: LinkButtonSizeVariants.SMALL
  }
]

const heroActions = [
  {
    label: 'Hoe werkt het?',
    href: '#unique-selling-points'
  },
  {
    label: 'Neem contact op',
    href: '#call-to-action',
    isCta: true
  }
]

const heroImages = [
  '/images/digibord-unicef.png'
  // 'http://via.placeholder.com/830x420'
]

const uniqueSellingPoints = [
  {
    title: 'Geoptimaliseerd voor alle doelgroepen',
    image: '/icons/gear-arrow.svg'
  },
  {
    title: 'Geschikt voor digibord / PC / laptop',
    image: '/icons/cursor.svg'
  },
  {
    title: 'Goed meetbaar, direct inzicht in gebruik',
    image: '/icons/ruler-square.svg'
  },
  {
    title: 'Houdt leerlingen bij de les door interacties',
    image: '/icons/people-connect.svg'
  }
]

const listItemsExampleOne = [
  'Je eigen unieke online les',
  'Inhoud afgestemd op curriculum'
]

const listItemsExampleTwo = [
  'Diverse vormen interacties',
  'Koppeling met device van leerling'
]

const listItemsExampleThree = [
  'Actief aanbieden bij het onderwijs',
  'Campagne op maat'
]

const partners = [
  '/partners/ntr.svg',
  '/partners/schooltv.svg',
  '/partners/unicef.svg',
  '/partners/jong-ondernemen.svg',
  '/partners/vodafone.svg',
  '/partners/nkh.svg'
]

const ctas: LinkButtonProps[] = [{
  label: 'Stuur een e-mail',
  href: `mailto:${EMAIL_ADDRESS}`
}, {
  label: 'Bekijk website',
  href: 'https://www.edg.nl',
  target: '_blank'
}]

const FrontPage = () => {
  return (
    <>
      <Head>
        <title>
          Edustart, jouw partner in het maken van lesmateriaal
        </title>
      </Head>
      <Navbar
        homeHref={Routes.ROOT}
        logo='/edustart-logo.svg'
        items={navbarItems}
      />
      <Hero
        styledText={{
          text: 'Klaar om jouw les in de klas te brengen?',
          variant: StyledTextVariant.UNDERLINE
        }}
        subtitle='Ontwikkel samen met Edustart een op maat gemaakte online les en breng jouw les direct in de klas.'
        heroImages={heroImages}
        heroImageOverlay='/images/person.png'
        backgroundImage='/icons/arrow-hero.svg'
        actions={heroActions}
      />
      <UniqueSellingPoints
        anchor='unique-selling-points'
        styledTitle={{
          text: 'Jouw partner in het maken van lesmateriaal',
          styledParts: ['Jouw partner'],
          variant: StyledTextVariant.COLOR
        }}
        styledDescription={{
          text: 'Edustart is het online platform waarbinnen eenvoudig online lessen ontwikkeld worden. Voor elke doelgroep, speciaal voor op digiborden en PC/laptops. Binnen het online platform zijn online lesstructuren en interactieve functionaliteiten al ontwikkeld. Je hoeft het wiel dus niet uit te vinden, dat hebben wij al voor je gedaan.',
          styledParts: ['Je hoeft het wiel', 'dus niet uit te vinden']
        }}
        usps={uniqueSellingPoints}
      />
      <ListSection
        styledTitle={{
          text: 'Je eigen les qua inhoud en design',
          styledParts: ['eigen les'],
          variant: StyledTextVariant.COLOR
        }}
        styledDescription={{
          text: 'Je wilt uiteraard wel een les die volledig bij jou past, zowel qua inhoud als qua design. Geen probleem! Edustart is zo ontwikkeld dat wij jouw online les helemaal bij je huisstijl aan kunnen laten sluiten. De content maken we graag samen met jou. We passen deze aan op doelgroep, verwerken aansprekende interacties en zorgen voor aansluiting bij het curriculum.',
          styledParts: ['bij je huisstijl aan kunnen', 'laten sluiten']
        }}
        listItems={listItemsExampleOne}
        tiltDirection={TiltDirection.RIGHT}
        image='/images/digibord-dierendiploma.png'
      />
      <ListSection
        styledTitle={{
          text: 'Zo houd je leerlingen bij de les',
          styledParts: ['bij de les'],
          variant: StyledTextVariant.COLOR
        }}
        styledDescription={{
          text: 'Door met verschillende interacties zoals quizvragen, stellingen, sleep- en koppelopdrachten en videocontent te werken, weet je de leerlingen steeds weer te verrassen en houd je de aandacht vast. Daarnaast is het mogelijk om een eigen device van de leerling aan de les te koppelen. De betrokkenheid wordt nog groter als ze zelf kunnen reageren op interactieve vraagstellingen.',
          styledParts: ['te verrassen en houd je de', 'aandacht vast']
        }}
        listItems={listItemsExampleTwo}
        image='/images/digibord-sinterklaas.png'
        reverse
      />
      <ListSection
        styledTitle={{
          text: 'Bied je les actief aan bij het onderwijs',
          styledParts: ['actief'],
          variant: StyledTextVariant.COLOR
        }}
        styledDescription={{
          text: 'Edustart is een product van EDG Media, hét mediabedrijf voor het onderwijs. Door onze jarenlange kennis en ervaring weten wij wat werkt. Naast het ontwikkelen van lessen binnen Edustart helpen wij je ook om je les(sen) actief aan te bieden aan het onderwijs. Hiervoor richten wij een speciale communicatiecampagne in.',
          styledParts: ['EDG Media', 'actief aan te bieden']
        }}
        listItems={listItemsExampleThree}
        tiltDirection={TiltDirection.RIGHT}
        image='/images/digibord-jongondernemen.png'
      />
      <Partners
        title='Deze organisaties maken al gebruik van edustart'
        partners={partners}
      />
      <Cta
        anchor='call-to-action'
        title='Nieuwsgierig geworden?'
        description={`Wij denken graag met je mee en vertellen je alles over de mogelijkheden van Edustart. Stuur een mail naar <a style="color: inherit;" href='mailto:${EMAIL_ADDRESS}'>${EMAIL_ADDRESS}</a> en wij nemen zo snel mogelijk contact met je op. Bekijk ook onze <a style='color: inherit;' href='https://edg.nl' target='_blank'>website</a> om te zien wat EDG Media nog meer voor je kan betekenen.`}
        ctas={ctas}
      />
      <Footer
        anchor='footer'
        cta={`Ben je op zoek naar een les? Stuur een bericht naar onze <a style='color: inherit;' href='mailto:${EMAIL_ADDRESS_CUSTOMER_SERVICE}'>klantenservice</a> en we helpen je verder.`}
        contact={{
          title: 'Edustart is onderdeel van EDG Media',
          email: EMAIL_ADDRESS,
          phone: '030 241 7020'
        }}
        address={{
          street: 'Niels Bohrweg 123',
          postalCode: '3542 CA',
          city: 'Utrecht',
          mapUrl: 'https://www.google.nl/maps/place/Niels+Bohrweg+123,+3542+CA+Utrecht/@52.1219094,5.0471014,17z/data=!3m1!4b1!4m5!3m4!1s0x47c66fcd8a9f6c93:0x1c8e79685b0929bc!8m2!3d52.1219094!4d5.0492901'
        }}
        postalAddress={{
          mailBox: 'Postbus 40266',
          postalCode: '3504 AB',
          city: 'Utrecht'
        }}
        socialMedia={[{
          url: 'https://www.linkedin.com/company/edgmedia/',
          icon: (
            <LinkedInLogoIcon
              height={24}
              width={24}
            />
          )
        }]}
        privacyPolicy={{
          label: 'Privacy- en cookiestatement',
          href: 'https://www.edg.nl/privacy-policy',
          target: '_blank'
        }}
        termsOfUse={{
          label: 'Gebruiksvoorwaarden',
          href: 'https://www.edg.nl/algemene-voorwaarden',
          target: '_blank'
        }}
        footerLogo='/edg-media-logo.svg'
      />
    </>
  )
}

export default FrontPage

import cx from 'classnames'

import FramedImage from '../FramedImage/FramedImage'
import LinkButton from '../LinkButton/LinkButton'
import type { StyledTextProps } from '../StyledText/StyledText'
import StyledText from '../StyledText/StyledText'
import styles from './Hero.module.css'

interface HeroProps<StyledPart extends string, Text extends string> {
  styledText: StyledTextProps<StyledPart, Text>
  subtitle: string
  heroImages: string[]
  heroImageOverlay?: string
  backgroundImage?: string
  actions: {
    label: string
    href: string
    isCta?: boolean
  }[]
}

const Hero = <StyledPart extends string, Text extends string>({
  styledText,
  subtitle,
  heroImages,
  heroImageOverlay,
  backgroundImage,
  actions
}: HeroProps<StyledPart, Text>) => {
  return (
    <section className={styles.component}>
      {backgroundImage && (
        <figure className={cx(styles.background, styles.backgroundTopLeft)}>
          <img
            src={backgroundImage}
            alt=''
          />
        </figure>
      )}
      {backgroundImage && (
        <figure className={cx(styles.background, styles.backgroundBottomRight)}>
          <img
            src={backgroundImage}
            alt=''
          />
        </figure>
      )}
      <div className={styles.container}>
        <h1 className={styles.title}>
          <StyledText {...styledText} />
        </h1>
        <h2 className={styles.subtitle}>
          {subtitle}
        </h2>
        <div className={styles.actions}>
          {actions.map((action, index) => {
            return (
              <LinkButton
                key={`${action.href}_${index}`}
                href={action.href}
                label={action.label}
                isCta={action.isCta}
              />
            )
          })}
        </div>
        <div className={styles.heroImage}>
          <FramedImage
            src={heroImages[0]}
            alt='Hero'
          />
        </div>
        {heroImageOverlay && (
          <figure className={styles.imagePerson}>
            <img
              src={heroImageOverlay}
              alt=''
            />
          </figure>
        )}
      </div>
    </section>
  )
}

export default Hero

import type { StyledTextProps } from '../StyledText/StyledText'
import StyledText from '../StyledText/StyledText'
import styles from './TextContent.module.css'

interface TextContentProps<StyledPart extends string, Text extends string, StyledPartDescription extends string, Description extends string> {
  preTitle?: string
  styledTitle: StyledTextProps<StyledPart, Text>
  styledDescription: StyledTextProps<StyledPartDescription, Description>
  listItems?: string[]
}

const TextContent = <StyledPart extends string, Text extends string, StyledPartDescription extends string, Description extends string>({
  styledTitle,
  preTitle,
  styledDescription,
  listItems
}: TextContentProps<StyledPart, Text, StyledPartDescription, Description>) => {
  return (
    <div className={styles.component}>
      <h2 className={styles.preTitle}>
        {preTitle}
      </h2>
      <h1 className={styles.title}>
        <StyledText {...styledTitle} />
      </h1>
      <p className={styles.description}>
        <StyledText {...styledDescription} />
      </p>
      <ul className={styles.list}>
        {listItems?.map((listItem, index) => {
          return (
            <li
              key={index}
              className={styles.listItem}
            >
              <figure>
                <img
                  src='/icons/arrow.svg'
                  alt={listItem}
                />
              </figure>
              <span>{listItem}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TextContent

import cx from 'classnames'

import styles from './StyledText.module.css'

export enum StyledTextVariant {
  UNDERLINE = 'UNDERLINE',
  COLOR = 'COLOR'
}

type EnsureStyledPartInText<StyledPart extends string, Text extends string> = Text extends `${string}${StyledPart}${string}` ? Text : never

export interface StyledTextProps<StyledPart extends string = string, Text extends string = string> {
  text: EnsureStyledPartInText<StyledPart, Text>
  styledParts?: StyledPart[]
  variant?: StyledTextVariant
}

const InternalStyledText = <StyledPart extends string, Text extends string>({
  text,
  styledParts,
  variant = StyledTextVariant.UNDERLINE
}: StyledTextProps<StyledPart, Text>) => {
  const regex = new RegExp(`(${styledParts?.join('|')})`, 'g')
  const parts = text?.split(regex)

  return (
    <>
      {parts?.map((part, index) => {
        return styledParts && styledParts.includes(part as any) ? (
          <span
            key={`${part}_${index}`}
            className={cx(styles.component, {
              [styles.styledUnderline]: variant === StyledTextVariant.UNDERLINE,
              [styles.styledColor]: variant === StyledTextVariant.COLOR
            })}
          >
            {part}
          </span>
        ) : (
          part
        )
      })}
    </>
  )
}

const StyledText = (props: StyledTextProps) => {
  return <InternalStyledText {...props} />
}

export default StyledText

import type { LinkButtonProps } from '../LinkButton/LinkButton'
import LinkButton from '../LinkButton/LinkButton'
import styles from './Cta.module.css'

interface CtaProps {
  anchor?: string
  title: string
  description: string
  ctas: LinkButtonProps[]
}

const Cta = ({
  anchor,
  title,
  description,
  ctas
}: CtaProps) => {
  return (
    <section
      id={anchor}
      className={styles.component}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            {title}
          </h1>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
          <div className={styles.ctas}>
            {ctas.map((cta, index) => {
              return (
                <LinkButton
                  key={`${cta.href}_${index}`}
                  {...cta}
                />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta

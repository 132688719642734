import cx from 'classnames'
import Link from 'next/link'

import styles from './LinkButton.module.css'

export enum LinkButtonSizeVariants {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
}

export interface LinkButtonProps {
  href: string
  label: string
  target?: string
  size?: LinkButtonSizeVariants
  isCta?: boolean
  transparent?: boolean
}

const LinkButton = ({
  href,
  label,
  size = LinkButtonSizeVariants.DEFAULT,
  transparent = false,
  isCta,
  target
}: LinkButtonProps) => {
  return (
    <Link
      href={href}
      scroll={false}
    >
      <a
        target={target}
        className={cx(styles.component, {
          [styles.cta]: isCta,
          [styles.small]: size === LinkButtonSizeVariants.SMALL,
          [styles.transparent]: transparent
        })}
      >
        {label}
      </a>
    </Link>
  )
}

export default LinkButton

import styles from './Partners.module.css'

interface PartnersProps {
  title: string
  partners: string[]
}

const Partners = ({
  title,
  partners
}: PartnersProps) => {
  return (
    <section className={styles.component}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          {title}
        </h1>
        <ul className={styles.partners}>
          {partners.map((partner, index) => {
            return (
              <li
                key={`${partner}_${index}`}
                className={styles.partner}
              >
                <img
                  src={partner}
                  alt='Partner'
                />
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Partners

import type { StyledTextProps } from '../StyledText/StyledText'
import TextContent from '../TextContent/TextContent'
import styles from './UniqueSellingPoints.module.css'

interface UniqueSellingPointsProps<StyledPart extends string, Text extends string, StyledPartDescription extends string, Description extends string> {
  anchor?: string
  styledTitle: StyledTextProps<StyledPart, Text>
  preTitle?: string
  styledDescription: StyledTextProps<StyledPartDescription, Description>
  usps: {
    title: string
    image: string
  }[]
}

const UniqueSellingPoints = <StyledPart extends string, Text extends string, StyledPartDescription extends string, Description extends string>({
  anchor,
  preTitle,
  styledTitle,
  styledDescription,
  usps
}: UniqueSellingPointsProps<StyledPart, Text, StyledPartDescription, Description>) => {
  return (
    <section
      id={anchor}
      className={styles.component}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <TextContent
            preTitle={preTitle}
            styledTitle={styledTitle}
            styledDescription={styledDescription}
          />
        </div>
        <div className={styles.usps}>
          {usps.map((usp, index) => {
            return (
              <div
                key={`usp-${index}`}
                className={styles.usp}
              >
                <figure>
                  <img
                    src={usp.image}
                    alt={usp.title}
                  />
                </figure>
                <p className={styles.uspTitle}>
                  {usp.title}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default UniqueSellingPoints

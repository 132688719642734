export enum Routes {
  ROOT = '/',
  LOGIN = '/admin',
  LOGOUT = '/logout',
  OVERVIEW = '/dashboard',
  PROJECTS = '/projects',
  PROJECT = '/[slug]',
  PROJECT_EDIT = '/projects/[slug]/edit',
  USERS = '/users',
  FORGOT_PASSWORD = '/forgot-password',
  PROFILE = '/profile'
}

import cx from 'classnames'

import type { TiltDirection } from '../FramedImage/FramedImage'
import FramedImage from '../FramedImage/FramedImage'
import type { StyledTextProps } from '../StyledText/StyledText'
import TextContent from '../TextContent/TextContent'
import styles from './ListSection.module.css'

interface ListSectionProps<StyledPart extends string, Text extends string, StyledPartDescription extends string, Description extends string>  {
  anchor?: string
  styledTitle: StyledTextProps<StyledPart, Text>
  styledDescription: StyledTextProps<StyledPartDescription, Description>
  listItems: string[]
  image: string
  tiltDirection?: TiltDirection
  reverse?: boolean
}

const ListSection = <StyledPart extends string, Text extends string, StyledPartDescription extends string, Description extends string>({
  anchor,
  styledTitle,
  styledDescription,
  listItems,
  image,
  tiltDirection,
  reverse
}: ListSectionProps<StyledPart, Text, StyledPartDescription, Description>) => {
  return (
    <section
      id={anchor}
      className={styles.component}
    >
      <div
        className={cx(styles.container, {
          [styles.reverse]: !!reverse
        })}
      >
        <div className={styles.image}>
          <FramedImage
            src={image}
            alt='List section'
            tiltDirection={tiltDirection}
          />
        </div>
        <div className={styles.content}>
          <TextContent
            styledTitle={styledTitle}
            styledDescription={styledDescription}
            listItems={listItems}
          />
        </div>
      </div>
    </section>
  )
}

export default ListSection

import cx from 'classnames'
import type { ImgHTMLAttributes } from 'react'

import styles from './FramedImage.module.css'

export enum TiltDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

interface FramedImageProps extends ImgHTMLAttributes<any> {
  tiltDirection?: TiltDirection
}

const FramedImage = ({
  tiltDirection = TiltDirection.LEFT,
  ...imageProps
}: FramedImageProps) => {
  return (
    <figure
      className={cx(styles.component, {
        [styles.tiltLeft]: tiltDirection === TiltDirection.LEFT,
        [styles.tiltRight]: tiltDirection === TiltDirection.RIGHT
      })}
    >
      <img {...imageProps} />
    </figure>
  )
}

export default FramedImage

import Link from 'next/link'
import React from 'react'

import type { LinkButtonProps } from '../LinkButton/LinkButton'
import LinkButton from '../LinkButton/LinkButton'
import styles from './Navbar.module.css'

interface NavbarProps {
  logo: string
  homeHref: string
  prefix?: string
  items: LinkButtonProps[]
}

const Navbar = ({
  logo,
  items,
  prefix,
  homeHref
}: NavbarProps) => {
  return (
    <nav className={styles.component}>
      <div className={styles.container}>
        <Link href={homeHref}>
          <a>
            <img
              src={logo}
              alt='logo'
            />
          </a>
        </Link>
        <div className={styles.nav}>
          {prefix && (
            <span className={styles.prefix}>
              {prefix}
            </span>
          )}
          <ul className={styles.navItems}>
            {items.map((item, index) => {
              return (
                <li key={item.href}>
                  <LinkButton
                    key={`${item.href}_${index}`}
                    {...item}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

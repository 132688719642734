import Link from 'next/link'
import type { ReactNode } from 'react'

import styles from './Footer.module.css'

interface FooterLinkProps {
  label: string
  href: string
  target?: string
}

interface FooterProps {
  anchor?: string
  cta?: string
  contact: {
    title: string
    email: string
    phone: string
    page?: FooterLinkProps
  }
  address: {
    street: string
    postalCode: string
    city: string
    mapUrl?: string
  }
  postalAddress: {
    mailBox: string
    postalCode: string
    city: string
  }
  socialMedia?: {
    url: string
    icon: ReactNode
  }[]
  privacyPolicy: FooterLinkProps
  termsOfUse: FooterLinkProps
  footerLogo: string
}

const Footer = ({
  anchor,
  cta,
  contact,
  address,
  postalAddress,
  socialMedia,
  privacyPolicy,
  termsOfUse,
  footerLogo
}: FooterProps) => {
  const currentYear = new Date().getFullYear()

  return (
    <footer
      id={anchor}
      className={styles.component}
    >
      <div className={styles.container}>
        <div className={styles.block}>
          <h3 className={styles.title}>
            {contact.title}
          </h3>
          <Link href={`mailto:${contact.email}`}>
            <a className={styles.link}>
              {contact.email}
            </a>
          </Link>
          <Link href={`tel:${contact.phone}`}>
            <a className={styles.link}>
              T: {contact.phone}
            </a>
          </Link>
          {contact?.page && (
            <Link href={contact.page.href}>
              <a
                target={contact.page.target}
                className={styles.link}
              >
                {contact.page.label}
              </a>
            </Link>
          )}
        </div>
        <div className={styles.block}>
          <h3 className={styles.title}>
            Bezoekadres
          </h3>
          <p className={styles.description}>
            {address.street}
          </p>
          <p className={styles.description}>
            {address.postalCode} {address.city}
          </p>
          {address.mapUrl && (
            <Link
              href={address.mapUrl}
            >
              <a
                className={styles.link}
                target='_blank'
                referrerPolicy='no-referrer'
              >
                Google Maps
              </a>
            </Link>
          )}
        </div>
        <div className={styles.block}>
          <h3 className={styles.title}>
            Postadres
          </h3>
          <p className={styles.description}>
            {postalAddress.mailBox}
          </p>
          <p className={styles.description}>
            {postalAddress.postalCode} {postalAddress.city}
          </p>
        </div>
        {socialMedia && (
          <div className={styles.block}>
            <h3 className={styles.title}>
              Volg ons
            </h3>
            <ul className={styles.socialMedia}>
              {socialMedia.map((item, index) => (
                <li
                  key={index}
                  className={styles.socialMediaItem}
                >
                  <Link href={item.url}>
                    <a
                      className={styles.socialMediaLink}
                      target='_blank'
                      referrerPolicy='no-referrer'
                    >
                      {item.icon}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <ul className={styles.linkList}>
              <li className={styles.socialMediaLink}>
                <Link href={privacyPolicy.href}>
                  <a
                    className={styles.link}
                    target={privacyPolicy.target}
                  >
                    {privacyPolicy.label}
                  </a>
                </Link>
              </li>
              <li className={styles.socialMediaLink}>
                <Link href={termsOfUse.href}>
                  <a
                    className={styles.link}
                    target={termsOfUse.target}
                  >
                    {termsOfUse.label}
                  </a>
                </Link>
              </li>
            </ul>
            <figure className={styles.logo}>
              <img
                className={styles.footerLogo}
                src={footerLogo}
                alt='EDG Media'
              />
            </figure>
          </div>
        )}
      </div>
      <div className={styles.copyrightContainer}>
        <div className={styles.copyright}>
          {cta && (
            <span
              dangerouslySetInnerHTML={{
                __html: cta
              }}
            />
          )}
          <span>
            © {currentYear} EDG Media
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
